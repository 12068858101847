import React from 'react'
import './PrivacyPolicy.scss'
import { Container, Row, Col } from 'react-bootstrap'
import Footer from '../../components/footer/Footer';

const PrivacyPolicy = () => {
    return (
        <>
            <article className="policy-container">
                <Container>
                    <Row>
                        <Col>
                            <h1>WisePrezzie Privacy Policy</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p style={{fontWeight: 'bold'}}>Last Updated: November 27, 2024</p>
                            <p>We at WisePrezzie Inc. (“WisePrezzie,” “we,” or “us”) are committed to safeguarding your personal information and ensuring transparency in how we collect, use, and share it. This Privacy Policy explains our practices for handling your data when you access or use our services (“Services”), including data obtained through third-party providers.
                                <br />
                                <br />
                                By using our Services, you agree to this Privacy Policy and our Terms of Use, which together govern your relationship with WisePrezzie.
                            </p>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            <h2>1. What This Policy Covers</h2>
                            <p>This Privacy Policy describes how WisePrezzie collects, uses, and protects personal information, including data obtained from third-party providers such as Google, Facebook, Stripe, and others.
                                <br />
                                <br />
                                We are committed to complying with all applicable laws, including but not limited to:
                            </p>
                            <ul>
                                <li>The California Consumer Privacy Act (CCPA)</li>
                                <li>The General Data Protection Regulation (GDPR)</li>
                                <li>Specific third-party data policies (e.g., Google API Services User Data Policy).</li>
                            </ul>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            <h2>2. What Information Does WisePrezzie Collect?</h2>
                            <span style={{fontStyle: 'normal'}}>Information You Provide to Us:</span>
                            <p style={{marginBottom: '0'}}>We collect information you voluntarily provide when registering,
                                creating a profile, or interacting with the Services, such as:
                                <br/>
                                <br/>
                            </p>
                            <ul>
                                <li>Name, email address, and phone number.</li>
                                <li>Account settings and preferences.</li>
                            </ul>
                            <span style={{fontStyle: 'normal'}}>Information Collected Automatically</span>
                            <p style={{marginBottom: '0'}}>We automatically collect data about your interactions with our
                                Services, such as:
                                <br/>
                                <br/>
                            </p>
                            <ul>
                                <li>Device information (e.g., IP address, browser type, operating system).</li>
                                <li>Usage data (e.g., pages visited, features used).</li>
                                <li>Cookies and similar tracking technologies.</li>
                            </ul>
                            <span style={{fontStyle: 'normal'}}>Information from Third-Party Providers</span>
                            <p style={{marginBottom: '0'}}>We may collect data from third-party providers through APIs, including but not limited to:</p>
                            <ul>
                                <li>Authentication providers (e.g., Google Sign-In, Facebook Login).</li>
                                <li>Payment processors (e.g., Stripe).</li>
                                <li>Data enrichment services or analytics tools.</li>
                            </ul>
                            <p>When connecting your account to third-party providers, you will be informed of the specific data being accessed and its purpose.</p>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            <h2>3. How WisePrezzie Uses Your Information</h2>
                            <p>We use your information to:</p>
                            <ul>
                                <li>Deliver and improve the Services.</li>
                                <li>Enable secure authentication (e.g., through Google Sign-In or other providers).</li>
                                <li>Analyze usage trends to enhance user experience.</li>
                                <li>Provide user-facing features and personalize content.</li>
                            </ul>
                            <p>
                                Third-Party Data Provider Compliance:
                            </p>
                            <p>
                                Data obtained via third-party APIs will be used in compliance with their respective privacy policies and terms of service. For example:
                            </p>
                            <ul>
                                <li>Google API Services: Data is used exclusively to enable user-facing features, such as account authentication.</li>
                                <li>Stripe Payment Services: Payment data is processed securely and in compliance with PCI-DSS standards.</li>
                            </ul>
                            <p>We do not use your data for unauthorized purposes, including targeted advertising, profiling, or creditworthiness evaluations.</p>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            <h2>4. How WisePrezzie Shares Your Information</h2>
                            <span style={{fontStyle: 'normal'}}>Third-Party Service Providers</span>
                            <p>We share data with trusted providers who enable our Services, such as:</p>
                            <ul>
                                <li>Authentication Services: Google Sign-In, Facebook Login.</li>
                                <li>Payment Processors: Stripe for secure transactions.</li>
                                <li>Analytics Tools: Tools to improve the Services.</li>
                            </ul>
                            <span style={{fontStyle: 'normal'}}>Publishers</span>
                            <p>
                            Certain Publishers may access limited data about users subscribing to their newsletters. Publishers must adhere to WisePrezzie’s standards for data protection and user consent.
                            </p>
                            <span style={{fontStyle: 'normal'}}>Legal Compliance and Security</span>
                            <p>
                                We may disclose your information to comply with legal obligations, enforce agreements, or protect the rights, property, or safety of WisePrezzie and others.
                            </p>
                            <span style={{fontStyle: 'normal'}}>Business Transfers</span>
                            <p>In the event of a merger, acquisition, or sale, user data may be transferred to the acquiring entity.</p>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            <h2>5. Your Rights and Choices</h2>
                            <span style={{fontStyle: 'normal'}}>Managing Permissions Across Providers</span>
                            <p>
                            You may manage or revoke permissions granted to third-party providers, such as Google, through your account settings or by contacting privacy@wiseprezzie.com.
                            </p>
                            <span style={{fontStyle: 'normal'}}>Access, Update, or Delete Your Information</span>
                            <p>You can access and update your account details or request deletion of your account by emailing privacy@wiseprezzie.com.</p>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            <h2>6. Data Protection and Security</h2>
                            <p>We implement robust security measures to protect your data, including:</p>
                            <ul>
                                <li>Encryption: Data is encrypted in transit and at rest.</li>
                                <li>Access Controls: Access to data is restricted to authorized personnel.</li>
                                <li>Incident Response: In the event of a data breach, affected users and relevant authorities will be notified promptly.</li>
                            </ul>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            <h2>7. Privacy for Childrens</h2>
                            <p>
                                WisePrezzie does not knowingly collect data from children under 13. If we become aware that such data has been collected, we will delete it promptly.
                            </p>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            <h2>8. Compliance with Third-Party Policies</h2>
                            <p>WisePrezzie complies with all applicable third-party data policies, including but not limited to:</p>
                            <ul>
                                <li>Google API Services User Data Policy.</li>
                                <li>Stripe Privacy Policy.</li>
                            </ul>
                            <p>
                                We commit to transparency and limited use for all third-party data, ensuring that it is only used to deliver user-facing features prominently displayed within the application.
                            </p>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            <h2>9. Changes to This Privacy Policy</h2>
                            <p>
                                We may update this Privacy Policy periodically to reflect changes in our practices or legal obligations. Users will be notified of significant updates and prompted to review and accept any modifications.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h2>10. Contact Us</h2>
                            <p>
                                For questions or concerns, contact us at:
                                Email: privacy@wiseprezzie.com
                            </p>
                        </Col>
                    </Row>
                </Container>
            </article>
            {/* <Footer /> */}
        </>
    )
}

export default PrivacyPolicy;