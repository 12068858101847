import React from 'react'
import './CCPAPolicy.scss'
import {Container, Row, Col} from 'react-bootstrap'
import Footer from '../../components/footer/Footer';

const CCPAPolicy = () => {
    return (
        <>
            <article className="policy-container">
                <Container>
                    <Row>
                        <Col>
                            <h1>WisePrezzie CCPA Policy</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p style={{fontWeight: 'bold'}}>Last Updated: Nov 27, 2024</p>
                            <p>
                                This CCPA Policy is incorporated by reference into the WisePrezzie Privacy Policy and
                                applies only to California consumers and their Personal Information. If you are a
                                California resident, the processing of your personal data may be subject to the
                                California Consumer Privacy Act (“CCPA”) and other applicable California state privacy
                                laws. For inquiries, please contact us at privacy@wiseprezzie.com.
                            </p>
                            <p>
                                Any capitalized terms not defined here have the meanings provided in our Privacy Policy
                                and/or the CCPA.
                            </p>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <h2>1. No Sale of Personal Information</h2>
                        <p>
                            Under the CCPA, a “sale” is defined as the exchange of personal information for valuable
                            consideration. WisePrezzie does not sell your Personal Information to third
                            parties.
                        </p>
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            <h2>2. Individual Rights Under the CCPA</h2>
                            <p style={{marginBottom: '0'}}>The CCPA grants California consumers several rights regarding their Personal
                                Information:</p>
                            <span style={{fontStyle: 'normal'}}>a. Right to Know</span>
                            <p>
                                You have the right to request the following information about our data practices over
                                the last 12 months:
                            </p>
                            <ul style={{listStyle: 'decimal'}}>
                                <li>Categories of Personal Information collected.</li>
                                <li>Categories of sources of Personal Information.</li>
                                <li>Business or commercial purposes for collecting Personal Information.</li>
                                <li>Categories of third parties with whom Personal Information is shared.</li>
                                <li>Categories of Personal Information disclosed for business purposes.</li>
                            </ul>
                            <span style={{fontStyle: 'normal'}}>b. Right to Access and Data Portability</span>
                            <p>
                            You may request specific pieces of Personal Information that we have collected about you
                                in a portable and readily usable format.
                            </p>
                            <span style={{fontStyle: 'normal'}}>c. Right to Delete</span>
                            <p>
                                You have the right to request deletion of your Personal Information, subject to certain
                                exceptions, such as:
                                <ul>
                                    <li>Completing transactions or services you requested.</li>
                                    <li>Complying with legal obligations.</li>
                                </ul>
                            </p>
                            <span style={{fontStyle: 'normal'}}>d. Non-Discrimination</span>
                            <p>
                                You have the right not to be discriminated against for exercising your rights under the
                                CCPA. WisePrezzie will not deny services, charge different rates, or provide a lower
                                quality of service for exercising your rights, except as permitted by the CCPA (e.g.,
                                offering financial incentives that are reasonably related to the value of your Personal
                                Information).
                            </p>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            <h2>3. Exercising Your Rights</h2>
                            <p>
                                To submit a request, please email privacy@wiseprezzie.com and include the following:
                                <ul style={{listStyle: 'decimal'}}>
                                    <li>A clear description of your request (e.g., the specific right(s) you wish to
                                        exercise).
                                    </li>
                                    <li>Sufficient information to verify your identity, such as your name and email
                                        address.
                                    </li>
                                </ul>
                            </p>
                            <p>
                                If you choose to designate an authorized agent, you must provide:
                                <ul>
                                    <li>A signed authorization allowing the agent to act on your behalf.</li>
                                    <li>Verification of your identity.</li>
                                </ul>
                            </p>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            <h2>4. Personal Information Categories Collected</h2>
                            <p>
                                In the last 12 months, we have collected the following categories of Personal
                                Information, as defined by the CCPA:
                            </p>
                            <table style={{border: '1px solid black'}}>
                                <thead>
                                    <tr>
                                        <th style={{border: '1px solid black', padding: '8px'}}>Category</th>
                                        <th style={{border: '1px solid black', padding: '8px'}}>Examples</th>
                                        <th style={{border: '1px solid black', padding: '8px'}}>Source(s)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{border: '1px solid black', padding: '8px', fontWeight: 'bold'}}>Identifiers</td>
                                        <td style={{border: '1px solid black', padding: '8px'}}>Name, email address, IP address</td>
                                        <td style={{border: '1px solid black', padding: '8px'}}>You</td>
                                    </tr>
                                    <tr>
                                        <td style={{border: '1px solid black', padding: '8px', fontWeight: 'bold'}}>Customer Records</td>
                                        <td style={{border: '1px solid black', padding: '8px'}}>Name</td>
                                        <td style={{border: '1px solid black', padding: '8px'}}>You</td>
                                    </tr>
                                    <tr>
                                        <td style={{border: '1px solid black', padding: '8px', fontWeight: 'bold'}}>Commercial Information</td>
                                        <td style={{border: '1px solid black', padding: '8px'}}>Records of products/services purchased</td>
                                        <td style={{border: '1px solid black', padding: '8px'}}>You</td>
                                    </tr>
                                    <tr>
                                        <td style={{border: '1px solid black', padding: '8px', fontWeight: 'bold'}}>Internet Activity</td>
                                        <td style={{border: '1px solid black', padding: '8px'}}>Browsing history on our Website</td>
                                        <td style={{border: '1px solid black', padding: '8px'}}>You, automatic collection</td>
                                    </tr>
                                    <tr>
                                        <td style={{border: '1px solid black', padding: '8px', fontWeight: 'bold'}}>Sensory Data</td>
                                        <td style={{border: '1px solid black', padding: '8px'}}>Audio files recorded during service use</td>
                                        <td style={{border: '1px solid black', padding: '8px'}}>You</td>
                                    </tr>
                                </tbody>
                            </table>
                            <p style={{marginTop: '10px'}}>
                                We will not collect additional categories or use collected Personal Information for
                                materially different purposes without providing you notice.
                            </p>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            <h2>5. Use of Personal Information</h2>
                            <p>
                                We use the above categories of Personal Information for the following purposes:
                            </p>
                            <ul>
                                <li style={{fontWeight: 'bold'}}>To deliver, support, and develop our website, products, and services.</li>
                                <li style={{fontWeight: 'bold'}}>To create and maintain secure user accounts.</li>
                                <li style={{fontWeight: 'bold'}}>To process transactions and prevent fraud.</li>
                                <li style={{fontWeight: 'bold'}}>To improve safety, security, and integrity across our systems.</li>
                            </ul>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            <h2>6. Sharing of Personal Information</h2>
                            <span style={{fontStyle: 'normal'}}>Business Purposes</span>
                            <p>We may share Personal Information with third parties to fulfill business purposes, such
                                as:</p>
                            <ul>
                                <li><span style={{fontWeight: 'bold', fontStyle: 'normal'}}>Authentication Services:</span> Examples include Google Sign-In or Facebook Login.</li>
                                <li><span style={{fontWeight: 'bold', fontStyle: 'normal'}}>Payment Processors:</span> Examples include Stripe for secure transactions.</li>
                                <li><span style={{fontWeight: 'bold', fontStyle: 'normal'}}>Analytics Providers:</span> For analyzing usage and improving our Services.</li>
                            </ul>
                            <span style={{fontStyle: 'normal'}}>Third-Party Data Providers</span>
                            <p>
                                We work with trusted third-party data providers, including Google API Services, to
                                enhance user-facing features and provide seamless functionality. For example:
                            </p>
                            <ul>
                                <li>
                                    Data accessed through Google API Services (e.g., user email) is used solely for
                                    authentication and user account management.
                                </li>
                            </ul>
                            <p>
                                All data shared with third-party providers is handled in compliance with applicable laws
                                and the provider’s terms of service.
                            </p>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            <h2>7. Data Security and Retention</h2>
                            <p>We implement robust security measures to safeguard Personal Information, including:</p>
                            <ul>
                                <li><span style={{fontWeight: 'bold', fontStyle: 'normal'}}>Encryption in Transit and at Rest:</span> Ensuring data is secure during transfer and
                                    storage.
                                </li>
                                <li><span style={{fontWeight: 'bold', fontStyle: 'normal'}}>Access Controls:</span> Limiting access to Personal Information to authorized personnel.
                                </li>
                                <li><span style={{fontWeight: 'bold', fontStyle: 'normal'}}>Incident Response:</span> Notifying affected users and authorities promptly in the event of
                                    a data breach.
                                </li>
                            </ul>
                            <p>We retain Personal Information only as long as necessary to fulfill the purposes outlined
                                in this Policy or to comply with legal obligations.</p>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            <h2>8. Changes to This CCPA Policy</h2>
                            <p>
                                WisePrezzie reserves the right to amend this CCPA Policy at any time. Updated versions
                                will be posted on our website with the effective date. Significant changes will be
                                communicated directly to users where required by law.
                            </p>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            <h2>9. Contact Us</h2>
                            <p style={{margin: '0'}}>For inquiries about this CCPA Policy or to exercise your rights, please contact us at:</p>
                            <p><span style={{fontWeight: 'bold', fontStyle: 'normal'}}>Email:</span> privacy@wiseprezzie.com</p>
                        </Col>
                    </Row>
                </Container>
            </article>
            {/* <Footer /> */}
        </>
    )
}

export default CCPAPolicy;